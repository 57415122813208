<template>
  <label
    :class="[
      'inline-flex cursor-pointer select-none items-center whitespace-nowrap',
      'rounded-full border border-gray-700',
      'px-6 pb-3.5 pt-4.5 text-sm1 font-extrabold uppercase leading-none tracking-wider',
      'transition has-[:checked]:border-black has-[:checked]:ring-2 has-[:checked]:ring-black',
      'hover:ring-2 hover:ring-black',
    ]"
  >
    <input
      type="checkbox"
      class="sr-only"
      tabindex="0"
      :checked="checked"
      @click="emit('update:checked', !checked)"
    />
    <slot />
  </label>
</template>

<script setup lang="ts">
defineProps<{
  checked?: boolean
}>()

const emit = defineEmits(['update:checked'])
</script>
